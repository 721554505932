/**
 * 活动相关请求模块
 */

import request from '@/utils/request'

export const getShopActivitiesActivityTypes = (data) => {
  return request({
    method: 'GET',
    url: '/cms/shop_activities/activity_types',
    data
  })
}

export const postShopActivities = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_activities',
    data
  })
}

export const putShopActivities = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_activities/${id}`,
    data
  })
}

export const deleteShopActivities = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_activities/${id}`
  })
}

export const getShopsSimpleList = (data) => {
  return request({
    method: 'GET',
    url: '/cms/shops/simple_list',
    data
  })
}

export const getShopItemsSimpleList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/simple_list',
    params
  })
}

export const getShopActivitiesIdShopActivityItems = (activityId) => {
  return request({
    method: 'GET',
    url: `/cms/shop_activities/${activityId}/shop_activity_items`
  })
}

export const getShopActivitiesIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_activities/${id}/preview`
  })
}

export const getShopActivitiesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_activities/setup'
  })
}

export const getShopActivities = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_activities',
    params
  })
}

export const postShopActivitiesIdItems = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/shop_activities/${id}/shop_activity_items`,
    data
  })
}

export const putShopActivitiesIdItemsId = (id, productId, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_activities/${id}/shop_activity_items/${productId}`,
    data
  })
}

export const getShopActivitiesIdItems = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/shop_activities/${id}/shop_activity_items`,
    params
  })
}

export const deleteShopActivitiesIdItemsId = (activityId, productId) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_activities/${activityId}/shop_activity_items/${productId}`
  })
}

export const getShopActivitiesIdItemsIdPreview = (activityId, productId) => {
  return request({
    method: 'GET',
    url: `/cms/shop_activities/${activityId}/shop_activity_items/${productId}/preview`
  })
}

export const getShopActivitiesIdStocksSimpleList = (productId) => {
  return request({
    method: 'GET',
    url: `/cms/shop_items/${productId}/shop_item_stocks/simple_list`
  })
}

export const putShopActivitiesIdItemsIdRank = (activityId, productId, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_activities/${activityId}/shop_activity_items/${productId}/update_rank`,
    data
  })
}

export const putShopActivitiesIdRank = (activityId, data) => {
  return request({
    method: 'PUT',
    url: `cms/shop_activities/${activityId}/update_rank`,
    data
  })
}

/**
 * 多件多折 Flexi Combo
 */

export const getFlexiComboSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/flexi_combos/setup'
  })
}

export const deleteFlexiCombo = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/flexi_combos/${id}`
  })
}

export const getFlexiCombos = (params) => {
  return request({
    method: 'GET',
    url: '/cms/flexi_combos',
    params
  })
}

export const addFlexiCombo = (data) => {
  return request({
    method: 'POST',
    url: '/cms/flexi_combos',
    data
  })
}

export const updateFlexiCombo = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/flexi_combos/${id}`,
    data
  })
}

export const getFlexiComboDiscountType = () => {
  return request({
    method: 'GET',
    url: '/cms/flexi_combos/discount_types'
  })
}

export const getFlexiComboPriview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/flexi_combos/${id}/preview`
  })
}

export const addFlexiComboProduct = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/flexi_combos/${id}/flexi_combo_items/batch_create`,
    data
  })
}

export const deleteFlexiComboProduct = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/flexi_combos/${id}/flexi_combo_items/batch_destroy`,
    data
  })
}

export const getFlexiComboProduct = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/flexi_combos/${id}/flexi_combo_items`,
    params
  })
}

export const getShopActivitiesDetail = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_activities/${id}`
  })
}
