// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
// import { postShopsFormData } from '@/services/store.js'
import * as shopsApi from '@/services/shops.js'
import * as bundleApi from '@/services/bundles.js'
import { getShopItemsSimpleList } from '@/services/promotion.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      collapseLength: [],
      formData: jsonData.formData,
      products: {
        pagination: {},
        shop_items: []
      }
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      shopsApi.getShopsSimpleList().then(res => {
        if (res.status === 200) {
          res.data.shops.forEach((item) => {
            const obj = {
              value: item.id,
              name: item.name
            }
            this.formData[0].createDateItem[2].options.push(obj)
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data) {
      const obj = {
        shop_id_eq: data,
        all: true,
        only_name: true
      }
      this.formData[0].createDateItem[10].createDateFirstItem[1].options = []
      getShopItemsSimpleList(obj).then((res) => {
        res.data.shop_items.forEach((item) => {
          const obj = {
            value: item.id,
            name: item.name
          }
          this.formData[0].createDateItem[10].createDateFirstItem[1].options.push(obj)
        })
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      // const bundle_items_attributes = []
      // for (let i = 0; i < obj.bundle_item.length; i++) {
      //   const item = {
      //     bundle_item_type: obj.bundle_item[i].item_type,
      //     shop_item_id: obj.bundle_item[i].item_product,
      //     discount_percent: Number(obj.bundle_item[i].item_discount)
      //   }
      //   bundle_items_attributes.push(item)
      // }
      const { online, discount_type, shop_id, name, name_en, start_date, end_date, short_description, short_description_en, bundle_items_attributes } = obj
      const params = {
        online,
        shop_id,
        name,
        name_en,
        start_date,
        end_date,
        short_description,
        short_description_en,
        discount_type,
        images_attributes: [
          {
            remote_image_url: obj.logo
          }
        ],
        bundle_items_attributes
      }
      bundleApi.createBundle(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.replace({
              name: 'bundleManagerList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
