import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 12,
      prop: 'discount_type',
      value: '',
      name: 'select',
      options: [
        {
          name: i18n.t('form.bundle.discountPercent'),
          value: 'percent_off'
        },
        {
          name: i18n.t('form.bundle.discountValue'),
          value: 'money_value_off'
        }
      ],
      label: i18n.t('form.bundle.discountType'),
      Object: {}
    }, {
      span: 12,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.common.shop'),
      Object: {
        nextChange: true,
        key: 'id'
      }
    }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.bundle.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.bundle.name_en'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'start_date',
      name: 'date',
      options: [],
      label: i18n.t('form.bundle.start_date'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'end_date',
      name: 'date',
      options: [],
      label: i18n.t('form.bundle.end_date'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'short_description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.bundle.shortDescriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 12,
      prop: 'short_description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.bundle.shortDescriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'logo',
      name: 'image',
      value: [],
      label: i18n.t('form.bundle.coverImage'),
      Object: {
        limit: 20,
        size: `${i18n.t('form.product.recommendedSize')}: 120x120`
      }
    }, {
      span: 24,
      prop: 'bundle_items_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.bundle.addBundle'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'bundle_item_type',
        name: 'select',
        options: [{
          name: i18n.t('form.bundle.main'),
          value: 'main'
        }, {
          name: i18n.t('form.bundle.optional'),
          value: 'optional'
        }],
        label: i18n.t('form.bundle.itemType'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 24,
        prop: 'shop_item_id',
        name: 'select',
        label: i18n.t('form.bundle.itemProduct'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        options: [],
        Object: {
          filterable: true,
          key: 'id'
        }
      }, {
        span: 24,
        prop: 'discount_percent',
        name: 'input',
        type: 'number',
        label: i18n.t('form.bundle.discountPercent2'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          visibleChange: [
            {discount_type: 'percent_off'}
          ]
        }
      }, {
        span: 24,
        prop: 'discount_value_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.bundle.discountValue3'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          visibleChange: [
            {discount_type: 'money_value_off'}
          ]
        }
      }]
    }]
  }]
}
