import request from '@/utils/request'

export const getShopsSimpleList = (data) => {
  return request({
    method: 'GET',
    url: '/cms/shops/simple_list',
    data
  })
}

export const getShopMap = (data) => {
  return request({
    method: 'GET',
    url: '/cms/shops/shop_map',
    data
  })
}
